<template>
  <b-container fluid>
               <b-row>
                  <b-col sm="12">
                     <iq-card>
                         <template v-slot:headerTitle>
                              <h4 class="card-title">Book List</h4>
                           </template>
                           <template v-slot:headerAction>
                              <router-link to="/books/add-book" class="btn btn-primary text-white">Add New book</router-link>
                           </template>
                        <template v-slot:body>
                          <div class="table-responsive mb-0 datatables">
                            <b-table
                              striped hover
                              :items="itemsWithNumber"
                              :fields="fields"
                              responsive="sm"
                              :per-page="perPage"
                              :current-page="currentPage"
                              :sort-by.sync="sortBy"
                              :sort-desc.sync="sortDesc"
                              :filter="filter"
                              :filter-included-fields="filterOn"
                              @filtered="onFiltered"
                              >
                              <template #cell(nama_file_cover)="data">
                                <img class="img-fluid rounded" :src="data.item.nama_file_cover" style="max-width: 100px" alt="">
                              </template>
                              <template #cell(nama_file)="data">
                                <a type="button" @click="$router.push('/books/book-pdf/' + data.item.id)"><i class="ri-file-fill text-secondary font-size-18"></i></a>
                              </template>
                              <template #cell(aksi)="data">
                                <div class="flex align-items-center list-user-action">
                                    <a style="color:white" class="bg-primary" data-toggle="tooltip" data-placement="top" title="" data-original-title="Edit" @click="$router.push('/books/edit-book/' + data.item.id)" type="button"><i class="ri-pencil-line"></i></a>
                                    <a style="color:white" class="bg-primary" data-toggle="tooltip" data-placement="top" title="" data-original-title="Delete" type="button" @click="deleteBuku(data.item.id)"><i class="ri-delete-bin-line"></i></a>
                                </div>
                              </template>
                              </b-table>
                          </div>
                          <div class="row ml-1">
                            <div>
                              <div id="tickets-table_length" class="dataTables_length">
                                  <label class="d-inline-flex align-items-center">
                                  <b-form-select class="form-control form-control-sm form-select form-select-sm" v-model="perPage" size="sm" :options="pageOptions"></b-form-select>
                                  </label>
                              </div>
                            </div>
                            <div class="ml-auto mr-3">
                              <b-pagination first-text="First"
                                prev-text="Prev"
                                next-text="Next"
                                last-text="Last" v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
                            </div>
                          </div>
                        </template>
                     </iq-card>
                  </b-col>
               </b-row>
  </b-container>
</template>
<script>
import { core } from '../../config/pluginInit'
import { mapGetters } from 'vuex'
import axios from 'axios'
export default {
  name: 'Book',
  mounted () {
    core.index()
    this.loaddata()
  },
  computed: {
    ...mapGetters({
      lang: 'Setting/langState'
    }),
    itemsWithNumber () {
      return this.dataBuku.map((d, index) => ({ ...d, no: index + 1 }))
    },
    rows () {
      return this.dataBuku.length;
    }
  },
  watch: {
  },
  data () {
    return {
      dataBuku: [],
      baseapi: localStorage.getItem("baseapi"),
      columnsDatatable: ['no', 'nama_file_cover', 'judul_buku', 'tahun', 'penulis', 'nama_file', 'aksi'],
      optionsDatatable: {
        perPage: 10,
        pagination: { chunk: 10, dropdown: false },
        texts: { filter: '', count: '', limit: '' },
        columnsClasses: { no: 'width-100', nama_file_cover: 'width-200', judul_buku: 'width-300', tahun: 'width-100', penulis: 'width-300', nama_file: 'width-300', aksi: 'width-300' },
        skin: 'table table-striped',
        sortIcon: {
          base: 'fa text-muted', up: 'fa-chevron-up', down: 'fa-chevron-down', is: 'fa-sort'
        }
      },
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "name",
      sortDesc: false,
      fields: [
          'no',
          { key: "nama_file_cover", label: 'Gambar', sortable: true },
          { key: "judul_buku", label: 'Judul', sortable: true },
          { key: "tahun", label: 'Tahun', sortable: true },
          { key: "penulis", label: 'Penulis', sortable: true },
          { key: "nama_file", label: 'PDF', sortable: true },
          'Aksi'
      ]
    }
  },
  methods: {
    onFiltered (filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    loaddata (){
      axios.get(this.baseapi + "buku", {
          headers: {
              // Authorization: `Bearer `+this.user.accessToken
          }
      }).then(response => {
          this.dataBuku = response.data.data;
      }).catch(error => {
          console.log(error)
          return error
          });
    },
    deleteBuku (id){
          axios
              .delete(
              this.baseapi + `buku/` + id,
              {
                  headers: {
                      // Authorization: `Bearer `+this.user.accessToken
                  }
              }
              )
              .then((res) => {
                this.loaddata()
              return res;
              })
              .catch((err) => {
              console.log(err)
                this.$toasted.error(err.response.data.message);
              return err;
          });
        }
  }
}
</script>